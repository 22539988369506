import "./styles.scss"

import React, { useEffect } from "react"

const ModalImage = ({ image, setImage }) => {
  useEffect(() => {
    if (image) {
      document.querySelector("body").classList.add("is-modal-open")
    } else {
      document.querySelector("body").classList.remove("is-modal-open")
    }

    return () =>
      document.querySelector("body").classList.remove("is-modal-open")
  }, [image])

  return (
    image && (
      <div className="modal-image">
        <div className="modal-image__container">
          <div className="modal-image__body">
            <img src={image} alt="" />
          </div>
        </div>
        <div
          className="modal-image__backdrop"
          role="presentation"
          onClick={() => setImage(null)}
        />
      </div>
    )
  )
}

export default ModalImage
